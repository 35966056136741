@tailwind base;
@tailwind components;
@tailwind utilities;
@import 'theme.css';

html,
body {
  padding: 0;
  margin: 0;
  font-family:
    -apple-system,
    BlinkMacSystemFont,
    Segoe UI,
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    Fira Sans,
    Droid Sans,
    Helvetica Neue,
    sans-serif;
  background-color: #e6e6ea;
  min-height: 100vh;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.rs-dropdown-item {
  font-weight: 500;
}

.rs-dropdown-item.rs-dropdown-item-focus {
  border-radius: 8px;
  background-color: #e6e6e6;
  color: #4544a2;
}

.rs-toggle-checked .rs-toggle-presentation {
  background-color: #4544a2;
}

.rs-toggle-presentation:hover {
  background-color: #363681;
}

.rs-toggle-checked .rs-toggle-presentation:hover {
  background-color: #363681;
}

.rs-picker-menu {
  z-index: 20;
}

.rs-nav-subtle .rs-nav-item:focus,
.rs-nav-subtle .rs-nav-item:hover {
  color: #4544a2;
}

.rs-nav-item.rs-nav-item-active {
  color: #4544a2;
}

.rs-nav-subtle .rs-nav-item.rs-nav-item-active:before {
  background-color: #4544a2;
}

.table :where(th, td) {
  white-space: initial;
}
