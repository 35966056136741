:root {
  --color-primary: #191a1b;
  --color-main-100: #f3f3f4;
  --color-main-300: #e7e7ea;
  --color-main-500: #ced0d5;
  --color-main-700: #b6b8bf;
  --color-main-900: #858995;
  --color-text-500: #545a6b;
  --color-background-200: #f8f9fd;
  --color-success-100: #e8f9e7;
  --color-success-500: #3dcc36;
  --color-info-100: #dde9fc;
  --color-info-500: #1a6eed;
  --color-warning-100: #fff2d9;
  --color-warning-500: #ffa800;
  --color-attention-100: #ffede8;
  --color-attention-500: #ff4e16;
  --color-danger-100: #ffe8e9;
  --color-danger-500: #ff3d47;
}

[data-theme='v1a3131'] {
  --color-primary: #002c55;
  --color-main-100: #f4f4f6;
  --color-main-300: #c4e398;
  --color-main-500: #d0d1d7;
  --color-main-700: #a9abb3;
  --color-main-900: #898a8d;
  --color-text-500: #50525e;
  --color-background-200: #f6f7fb;
  --color-success-100: #d0f1d0;
  --color-success-500: #01bf17;
  --color-info-100: #a6c8f7;
  --color-info-500: #3758e2;
  --color-warning-100: #ffdcb3;
  --color-warning-500: #fc5b0b;
  --color-attention-100: #ffbc99;
  --color-attention-500: #ff0800;
  --color-danger-100: #ffb3b5;
  --color-danger-500: #c31807;
}
